<template>
  <div v-if="checkPermission('list', 'action', 'whitelists')">
    <topic-component :topic="$t('SideBarItems.setting.list.whitelist')"/>
    <b-card>
      <b-row>
        <b-col lg="3" md="3" sm="4">
          <b-form-group label-cols="12"
                        :label="$t('setting.whitelistIP.form.whitelistIP')" label-for="input-sm">
            <b-form-input v-model="form.whitelist_ip"/>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="3" sm="4">
          <select-filter-component
              :label-select="$t('setting.senderID.list.status')"
              :options="statusList"
              v-model="form.status"
          />
        </b-col>
        <b-col style="margin-top: 35px">
          <button type="button" class="btn waves-effect waves-light btn-gradient" @click="findWhitelistIP">
            {{ $t('common.Search') }}
          </button>
          <button type="button" class="btn waves-effect waves-light btn-outline ml-2" @click="resetSearch">
            {{ $t('common.resetSearch') }}
          </button>
        </b-col>
      </b-row>
      <hr>
      <b-row align-v="center" class="pb-3">
        <b-col>
          <div class="d-flex align-items-center">
            <span class="mr-3">{{$t('common.perPage')}}</span>
            <div>
              <b-form-select
                  v-model="form.$limit"
                  :options="pageOptions"
                  size="sm"
                  @change="findWhitelistIP"
              />
            </div>
            <div class="ml-3">
              <total-list-component :total="totalAll"/>
            </div>
          </div>
        </b-col>
        <b-col align="end">
          <b-button variant="primary" v-b-modal.modal-create-whitelist-ip
                    :disabled="!checkPermission('create', 'action', 'whitelists')"><i class="fas fa-plus-circle"></i>
            {{ $t("setting.requestWhitelist") }}
          </b-button>
        </b-col>
      </b-row>
      <b-table
          responsive
          class="mb-0"
          :items="items"
          :fields="fields"
          :busy="isBusy"
          :empty-text="$t('empty')"
          show-empty
      >
        <template #head()="{ label, field: { key, sortable }}">
          {{ $t(label) }}
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong> {{ $t('loading') }}</strong>
          </div>
        </template>
        <template #empty="scope">
          <empty-table/>
        </template>
        <template #cell(status)="data">
          <b-badge pill :class="`px-2 text-white badge ${colorButton(data.item.status)}`">
            {{ filterValue(statusList, data.item.status) }}
          </b-badge>
        </template>
        <template #cell(createdAt)="data">
          {{ changeDateFormat(data.item.createdAt) }}
        </template>
        <template #cell(action)="data">
          <i style="color: #DF3F1E" @click="deleteWhitelist(data.item._id)" class="action-button icon-trash "></i>
        </template>
      </b-table>
      <b-row>
        <div class="ml-auto">
          <b-pagination
              v-model="form.$skip"
              :total-rows="totalRows"
              :per-page="form.$limit"
              align="fill"
              class="my-0"
              size="sm"
              @input="findWhitelistIP"
          />
        </div>
      </b-row>
      <create-white-list-ip @output="findWhitelistIP"/>
    </b-card>
  </div>
</template>

<script>


import CreateWhiteListIp from "@/views/setting/whitelist/create";
import TopicComponent from "@/components/common/topicComponent";
import masterData from "@/common/masterData.json";
import whiteListApi from "@/repository/whiteListApi";
import SelectFilterComponent from "@/components/common/selectFilterComponent";
import functionsCommon from "@/common/functions";
import alert from "@/common/alert";
import functions from "@/common/functions";
import EmptyTable from "@/components/common/emptyTable.vue";
import TotalListComponent from "@/components/common/totalList.vue";

export default {
  name: "WhiteList",
  components: {TotalListComponent, EmptyTable, SelectFilterComponent, TopicComponent, CreateWhiteListIp},
  data: () => ({
    totalAll: 0,
    form: {
      status: null,
      $skip: 1,
      $limit: 10,
      whitelist_ip: '',
    },
    statusList: masterData.whiteListStatusList,
    isBusy: false,
    fields: [
      {
        key: "whitelist_ip",
        label: "setting.whitelistIP.form.whitelistIP",
      },
      {
        key: "status",
        label: "setting.senderID.list.status",
      },
      {
        key: "createdAt",
        label: "setting.senderID.list.createdAt",
      },
      // {
      //   key: "action",
      //   label: "setting.senderID.list.action",
      // },
    ],
    items: [],
    totalRows: 1,
    pageOptions: [10, 15, 50, 100],
  }),
  mounted() {
    this.findWhitelistIP()
  },
  methods: {
    changeDateFormat(date) {
      return date ? functionsCommon.changeDateFormat(date) : '-'
    },
    colorButton(data) {
      switch (data) {
        case 'active':
          return 'bg-success';
          break;
        case 'disapproved':
          return 'bg-grey';
          break;
        case 'blocked':
          return 'bg-danger';
          break;
        case 'block':
          return 'bg-danger';
          break;
        case 'pending':
          return 'bg-warning';
          break;
        default:
          return
      }
    },
    filterValue(arr, val) {
      return functionsCommon.filterMasterData(arr, val, this.$i18n.locale)
    },
    findWhitelistIP() {
      this.isBusy = !this.isBusy
      whiteListApi.getAll(_.cloneDeep(this.form)).then(response => {
        this.totalAll = response.total
        this.totalRows = response.total
        this.items = response.data
      }).catch(err => {
        // alert.findFailed(this, err)
      }).finally(() => {
        this.isBusy = !this.isBusy
      })

    },
    deleteWhitelist(id) {
      this.$swal.fire({
        icon: 'warning',
        title: this.$t('common.delete.topic'),
        text: this.$t('common.delete.detail'),
        showCancelButton: true,
        confirmButtonColor: '#EA5455',
        cancelButtonColor: '#7367F0',
        cancelButtonText: this.$t('button.cancel'),
        confirmButtonText: this.$t('button.yes'),
      }).then((result) => {
        if (result.isConfirmed) {
          whiteListApi.delete(id).then(response => {
            if (response?.codeSYS === '001') {
              this.findWhitelistIP()
              alert.deleteSuccess(this)
            } else {
              alert.deleteFailed(this, response.message)
            }
          }).catch(err => {
            alert.deleteFailed(this)
          })
        }
      })
    },
    resetSearch() {
      this.form = {
        status: null,
        $skip: 1,
        $limit: 10,
        whitelist_ip: '',
      }
      this.findWhitelistIP()
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    }
  }
};
</script>
<style scoped>
.action-button {
  cursor: pointer;
}
</style>
