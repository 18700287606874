<template>
  <b-modal
      id="modal-create-whitelist-ip"
      size="xl"
      hide-footer
      no-close-on-backdrop
  >
    <template #modal-header="{ close }">
      <h5>{{ $t('setting.requestWhitelist') }}</h5>
      <i class="fas fa-times hover-icon action-button" @click="clearData()"></i>
    </template>
    <b-row>
      <b-col sm="12" lg="6" md="6">
        <div>
          <input-component :label-input="$t('setting.whitelistIP.form.whitelistIP')"
                           v-model="form.whitelist_ip"
                           required
                           :state="validateState('whitelist_ip')"
                           :require-label="validateState('whitelist_ip') !== null && !$v.form.whitelist_ip.required  ? $t('common.requiredField') : ''"

          />
          <b-row align-h="end" class="mr-1 mt-3">
            <b-button variant="primary"
                      :disabled="$v.form.$invalid"
                      @click="createWhitelist">
              <i class="fas fa-save"/>
              {{ $t('button.save') }}
            </b-button>
          </b-row>
        </div>
      </b-col>
      <b-col sm="12" lg="6" md="6">
        <label class="label-for-input ml-4 mr-4">
          {{ $t('setting.whitelistIP.conditions') }}
        </label>
        <div class=" ml-4 mr-4" v-html="$t('setting.whitelistIP.note')"/>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import InputComponent from "@/components/common/inputComponent";
import InputTextAreaComponent from "@/components/common/textareaComponent";
import SelectComponent from "@/components/common/selectComponent";
import whiteListApi from "@/repository/whiteListApi"
import alert from "@/common/alert";
import {validationMixin} from "vuelidate";
import {required} from "vuelidate/lib/validators";

export default {
  name: "createWhiteListIp",
  components: {SelectComponent, InputTextAreaComponent, InputComponent},
  mixins: [validationMixin],
  data() {
    return {
      form: {
        whitelist_ip: '',
      }
    }
  },
  validations: {
    form: {
      whitelist_ip: {
        required,
      }
    },
  },
  methods: {
    validateState(username) {
      const {$dirty, $error} = this.$v.form[username];
      return $dirty ? !$error : null;
    },
    createWhitelist() {
      whiteListApi.create(this.form).then(response => {
        if (response?.codeSYS === '001') {
          this.$emit('output', 'success');
          this.clearData()
          alert.addNewSuccess(this)
        } else {
          alert.addNewFailed(this, response.message)
        }
      }).catch(err => {
        alert.addNewFailed(this, err.response?.data?.message)
      })
    },
    clearData() {
      const data = {
        whitelist_ip: "",
      }
      this.form = data
      this.$v.form.$reset()
      this.$bvModal.hide('modal-create-whitelist-ip')
    }
  }
}
</script>

<style scoped>

</style>